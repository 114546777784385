<template>
	<v-dialog
    v-model="dialogMaps.estatus"
    hide-overlay
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="text-h5">
        LINK MAPS
      </v-card-title>
      <v-card-text>
  			<v-textarea
  				v-model="codigo.link_maps"
  				rows="3"
  				auto-grow
  				label="Agregar acceso al zoom"
  				filled
  				hide-details
  				readonly
  			/>

      </v-card-text>
      <v-card-actions>
      	<v-btn
          color="black"
          tile
          dark
          small
          @click="dialogMaps.estatus = false"
        >
        	<v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>

        <v-btn
          color="orange"
          tile
          dark
          small
          @click="copiarPortapapeles( codigo.link_maps )"
        >
        	<v-icon small left>mdi-content-copy</v-icon>
          Copiar
        </v-btn>

        <v-btn
          color="green"
          tile
          dark
          small
          @click="abrirMaps( codigo.link_maps )"
        >
        	<v-icon small left>mdi-lock-open</v-icon>
          Abrir Maps
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogMaps',
			'codigo',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      headersCerrados: [
        { text: 'Folio',          value: 'id_usuario'      },
        { text: 'Nombre',         value: 'nombre_completo' },  
        { text: 'Teléfono',       value: 'telefono1'       },  
        { text: 'Sucursal',       value: 'plantel'         },  
      ],
      search:'',
      curso: null,
      opcion: 1

    }),

    
    methods:{
      cerrar(){
      	this.dialogMaps.estatus = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      },

      copiarPortapapeles ( item ) {

      	// Expresión regular para encontrar enlaces en el texto
				var expresionRegular = /https?:\/\/[^\s]+/g;

				// Usar el método match() para obtener todos los enlaces en un arreglo
				var enlaces = item.match(expresionRegular);

        navigator.clipboard.writeText( enlaces[0] ).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      abrirMaps( item ){

				// Expresión regular para encontrar enlaces en el texto
				var expresionRegular = /https?:\/\/[^\s]+/g;

				// Usar el método match() para obtener todos los enlaces en un arreglo
				var enlaces = item.match(expresionRegular)[0];

				window.open(enlaces, "_blank");


      },



    }
	}
</script>
