<template>
	<v-dialog
    v-model="dialogFalta.estatus"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="text-h5">
        Faltaré a clases
      </v-card-title>
      <v-card-text>
  			<v-textarea
  				rows="3"
  				auto-grow
  				label="motivo"
  				filled
  				hide-details
  				v-model="motivo"
  			/>

      </v-card-text>
      <v-card-actions>
      	<v-btn
          color="black"
          tile
          dark
          small
          @click="cerrar()"
        >
        	<v-icon small left>mdi-cancel</v-icon>
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="green"
          tile
          dark
          small
          @click="save()"
        >
        	<v-icon small left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'dialogFalta',
			'id_teacher',
			'bloque',
			'grupo'
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',

    }),

    created(){
    	console.log( this.grupo )
    },

    methods:{
      cerrar(){
      	this.dialogFalta.estatus = false
      	this.$emit('consultar')
      },

      save( ){

      	if( !this.motivo ){ return this.validarErrorDirecto('Favor de llenar el motivo') }

    		const payload = {
					id_teacher:   this.id_teacher,
					bloque:       this.bloque,    
					idrol_clases: this.grupo.idrol_clases,
					motivo:       this.motivo,
					grupo:        this.grupo.grupo,
					teacher:      this.grupo[`${this.bloque}_n`],
					id_plantel:   this.grupo.id_plantel		
    		}

    		this.cargar = true
        // Obtener todos los ciclos
        this.$http.post('rol.faltar', payload ).then(response=>{
      		
      		this.dialogFalta.estatus = false
          
      		this.$emit('consultar')

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },



    }
	}
</script>
