<template>
  <v-container class="mt-4 px-5" fluid>
    <v-row>
      <v-col cols="12">
      	
      	<v-card class="shadowCard">
      	  <v-card-title class="text-subtitle-1">
      	    Rol de teachers
      	    <v-spacer></v-spacer>

      	  	<!-- Botón para consultar la iformación al sistema -->
      	    <v-btn 
              small dark color="orange"
              @click="consultar()"
              tile
            >
              <v-icon small left>mdi-refresh</v-icon>
              Consultar
            </v-btn>

      	  </v-card-title>

      	  <!-- Tabla y filtros -->
      	  <v-card-text>

      	  	<!-- Filtro de ciclos -->
      	    <v-row justify="space-between">
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="6">
                <v-text-field
                  v-model="search"
                  label="Búscar"
                  dense
                  filled
                  hide-details
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- si existe un rol para aceptar aparecera aqui -->
            <!-- <v-row justify="space-between" v-if="teacherGruposJSON.length">
              <v-col cols="6">
                <h3>ROL ASIGNADO:</h3>
              </v-col>
              <v-col cols="6">
                <h3> TOTAL DE GRUPOS: {{ teacherGruposJSON.length }} </h3>
              </v-col>
            </v-row>
            <v-row justify="space-between" v-if="teacherGruposJSON.length">
              <v-col cols="9">
                <span v-for="(grupoT, i) in teacherGruposJSON" :key="i">
                  <v-icon>mdi-circle-small</v-icon> {{ grupoT.nombre_grupo }} <br>
                </span>
              </v-col>
              <v-col>
                <v-btn color="error" @click="aceptarRol(2)" v-if="teacherJSON.aceptadoXTeacher == 0" class="mb-4"> Rechazar Rol </v-btn>
                <v-btn color="success" @click="aceptarRol(1)" v-if="teacherJSON.aceptadoXTeacher == 0"> Aceptar Rol </v-btn>
                <span v-if="teacherJSON.aceptadoXTeacher == 2">Rol rechazado</span>
                <span v-if="teacherJSON.aceptadoXTeacher == 1">Rol aceptado</span>
              </v-col>
            </v-row> -->

            <!-- Mostrar información por bloques -->
            <v-row justify="space-between">
              <v-col cols="12" md="6" lg="6">
                <v-radio-group
                  v-model="selectedHeaders"
                  row
                  hide-details
                >
                  <v-radio
                    label="Semana 1"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Semana 2"
                    :value="2"
                  ></v-radio>
                  <v-radio
                    label="Semana 3"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    label="Semana 4"
                    :value="4"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              
            </v-row>

            <!-- Check para los eliminados y optimizados -->
            <v-row class="mb-2">
            	<!-- Filtrar por teacher -->
            	<v-col cols="12" md="3" lg="4">
                <h3> TOTAL DE GRUPOS: {{ filterGrupos.length }} </h3>
                <!-- <h3> TOTAL DE HORAS: {{ totalHoras }} </h3> -->
                <!-- <h3> TOTAL DE CLASES: {{ totalClases }} </h3> -->
						    <!-- <h3> PAGO TOTAL: {{ formatMoney( pagoTotal ) }} </h3> -->
            	</v-col>

              <v-col cols="12" md="3" lg="4">
              </v-col>
            </v-row>
		        
            <v-btn color="green" dark tile v-if="confirmaRol" @click="confirmar" class=" mr-4">ROL CORRECTO</v-btn>
            <v-btn color="red" dark tile v-if="confirmaRol" @click="rechazar">DETALLE EN ROl</v-btn>
		        <!-- Tabla con toda la información -->
		        <v-data-table
		          :headers="headers"
		          :items="filterGrupos"
		          class="elevation-0 mt-2"
		          height="500"
		          dense
		          fixed-header
		          :search="search"
		          multi-sort
		          :loading="cargar"
		        > 
		        	<template v-slot:item.grupo="{ item }">
	        		<span type="button" v-if="item.deleted == 0" @click="codigo = item, dialogZoom = true" :class=" item.grupo.match('ONLINE') ? 'subrayado' : ''"> {{ item.grupo }} </span>
		        		<span type="button" v-else  @click="codigo = item, dialogZoom = true" class="subrayado red--text"> {{ item.grupo }} </span>
		          </template>

		          <template v-slot:item.optimizado="{ item }">
		            <v-chip v-if="item.optimizado == 1" small rounded color="green" dark >Optimizado</v-chip>
		          </template>

		          <template v-slot:item.modalidad="{ item }">
		            <v-chip v-if="item.modalidad == 1" small rounded color="green"  dark >Online</v-chip>
		            <v-chip v-if="item.modalidad == 0" small rounded color="orange" dark >Presencial</v-chip>
		          </template>

		          <template v-slot:item.id_unidad_negocio="{ item }">
		            <v-chip v-if="item.id_unidad_negocio == 1" small rounded color="blue lighten-2" dark >INBI</v-chip>
		            <v-chip v-else small rounded color="blue darken-2" dark >FAST</v-chip>
		          </template>

              <template v-slot:item.plantel="{ item }">
                <span class="subrayado" @click="codigo = item, dialogMaps.estatus = true">{{ item.plantel }}</span>
              </template>

		          <!-- Editar Bloque 1 -->
		          <template v-slot:item.lu1_n="{ item }">
		            <span v-if="item.lu1 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'lu1', 
                  id_teacher = item.lu1, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.lu1_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.lu1_n }}
                  <br/>
                  {{ item.lu1_hora_asistencia ? item.lu1_hora_asistencia : '' }}
                </span>
                <div v-if="item.lu1 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu1', item.lu1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma1_n="{ item }">
		            <span v-if="item.ma1 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ma1', 
                  id_teacher = item.ma1, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ma1_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ma1_n }}
                  <br/>
                  {{ item.ma1_hora_asistencia ? item.ma1_hora_asistencia : '' }}
                </span>
                <div v-if="item.ma1 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma1', item.ma1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi1_n="{ item }">
		            <span v-if="item.mi1 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'mi1', 
                  id_teacher = item.mi1, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.mi1_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.mi1_n }}
                  <br/>
                  {{ item.mi1_hora_asistencia ? item.mi1_hora_asistencia : '' }}
                </span>
                <div v-if="item.mi1 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi1', item.mi1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju1_n="{ item }">
		            <span v-if="item.ju1 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ju1', 
                  id_teacher = item.ju1, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ju1_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ju1_n }}
                  <br/>
                  {{ item.ju1_hora_asistencia ? item.ju1_hora_asistencia : '' }}
                </span>
                <div v-if="item.ju1 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju1', item.ju1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi1_n="{ item }">
		            <span v-if="item.vi1 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'vi1', 
                  id_teacher = item.vi1, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.vi1_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.vi1_n }}
                  <br/>
                  {{ item.vi1_hora_asistencia ? item.vi1_hora_asistencia : '' }}
                </span>
                <div v-if="item.vi1 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi1 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi1.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi1', item.vi1, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>


		          <!-- Editar Bloque 2 -->
		          <template v-slot:item.lu2_n="{ item }">
		            <span v-if="item.lu2 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'lu2', 
                  id_teacher = item.lu2, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.lu2_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.lu2_n }}
                  <br/>
                  {{ item.lu2_hora_asistencia ? item.lu2_hora_asistencia : '' }}
                </span>
                <div v-if="item.lu2 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu2', item.lu2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma2_n="{ item }">
		            <span v-if="item.ma2 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ma2', 
                  id_teacher = item.ma2, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ma2_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ma2_n }}
                  <br/>
                  {{ item.ma2_hora_asistencia ? item.ma2_hora_asistencia : '' }}
                </span>
                <div v-if="item.ma2 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma2', item.ma2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi2_n="{ item }">
		            <span v-if="item.mi2 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'mi2', 
                  id_teacher = item.mi2, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.mi2_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.mi2_n }}
                  <br/>
                  {{ item.mi2_hora_asistencia ? item.mi2_hora_asistencia : '' }}
                </span>
                <div v-if="item.mi2 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi2', item.mi2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju2_n="{ item }">
		            <span v-if="item.ju2 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ju2', 
                  id_teacher = item.ju2, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ju2_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ju2_n }}
                  <br/>
                  {{ item.ju2_hora_asistencia ? item.ju2_hora_asistencia : '' }}
                </span>
                <div v-if="item.ju2 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju2', item.ju2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi2_n="{ item }">
		            <span v-if="item.vi2 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'vi2', 
                  id_teacher = item.vi2, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.vi2_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.vi2_n }}
                  <br/>
                  {{ item.vi2_hora_asistencia ? item.vi2_hora_asistencia : '' }}
                </span>
                <div v-if="item.vi2 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi2 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi2.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi2', item.vi2, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>


		          <!-- Editar Bloque 3 -->
		          <template v-slot:item.lu3_n="{ item }">
		            <span v-if="item.lu3 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'lu3', 
                  id_teacher = item.lu3, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.lu3_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.lu3_n }}
                  <br/>
                  {{ item.lu3_hora_asistencia ? item.lu3_hora_asistencia : '' }}
                </span>
		            <div v-if="item.lu3 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu3', item.lu3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
              </template>

		          <template v-slot:item.ma3_n="{ item }">
		            <span v-if="item.ma3 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ma3', 
                  id_teacher = item.ma3, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ma3_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ma3_n }}
                  <br/>
                  {{ item.ma3_hora_asistencia ? item.ma3_hora_asistencia : '' }}
                </span>
		            <div v-if="item.ma3 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma3', item.ma3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
              </template>

		          <template v-slot:item.mi3_n="{ item }">
		            <span v-if="item.mi3 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'mi3', 
                  id_teacher = item.mi3, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.mi3_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.mi3_n }}
                  <br/>
                  {{ item.mi3_hora_asistencia ? item.mi3_hora_asistencia : '' }}
                </span>
		            <div v-if="item.mi3 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi3', item.mi3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
              </template>

		          <template v-slot:item.ju3_n="{ item }">
		            <span v-if="item.ju3 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ju3', 
                  id_teacher = item.ju3, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ju3_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ju3_n }}
                  <br/>
                  {{ item.ju3_hora_asistencia ? item.ju3_hora_asistencia : '' }}
                </span>
		            <div v-if="item.ju3 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju3', item.ju3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
              </template> 

		          <template v-slot:item.vi3_n="{ item }">
		            <span v-if="item.vi3 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'vi3', 
                  id_teacher = item.vi3, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.vi3_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.vi3_n }}
                  <br/>
                  {{ item.vi3_hora_asistencia ? item.vi3_hora_asistencia : '' }}
                </span>
		            <div v-if="item.vi3 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi3 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi3.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi3', item.vi3, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
              </template>


		          <!-- Editar Bloque 4 -->
		          <template v-slot:item.lu4_n="{ item }">
		            <span v-if="item.lu4 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'lu4', 
                  id_teacher = item.lu4, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.lu4_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.lu4_n }}
                  <br/>
                  {{ item.lu4_hora_asistencia ? item.lu4_hora_asistencia : '' }}
                </span>
                <div v-if="item.lu4 == getdatosUsuario.iderp && ( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.llu4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.llu4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.lunes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('lu4', item.lu4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ma4_n="{ item }">
		            <span v-if="item.ma4 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ma4', 
                  id_teacher = item.ma4, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ma4_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ma4_n }}
                  <br/>
                  {{ item.ma4_hora_asistencia ? item.ma4_hora_asistencia : '' }}
                </span>
                <div v-if="item.ma4 == getdatosUsuario.iderp && ( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lma4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lma4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.martes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ma4', item.ma4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.mi4_n="{ item }">
		            <span v-if="item.mi4 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'mi4', 
                  id_teacher = item.mi4, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.mi4_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.mi4_n }}
                  <br/>
                  {{ item.mi4_hora_asistencia ? item.mi4_hora_asistencia : '' }}
                </span>
                <div v-if="item.mi4 == getdatosUsuario.iderp && ( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lmi4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lmi4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.miercoles == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('mi4', item.mi4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.ju4_n="{ item }">
		            <span v-if="item.ju4 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'ju4', 
                  id_teacher = item.ju4, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.ju4_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.ju4_n }}
                  <br/>
                  {{ item.ju4_hora_asistencia ? item.ju4_hora_asistencia : '' }}
                </span>
                <div v-if="item.ju4 == getdatosUsuario.iderp && ( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lju4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lju4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.jueves == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('ju4', item.ju4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>

		          <template v-slot:item.vi4_n="{ item }">
		            <span v-if="item.vi4 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 )" 
                  @click="bloque = 'vi4', 
                  id_teacher = item.vi4, 
                  grupo = item, 
                  dialogFalta.estatus = true"
                  type="button"
                  :class="item.vi4_falta ? 'red darken-2 white--text' : '' "
                >
                  {{ item.vi4_n }}
                  <br/>
                  {{ item.vi4_hora_asistencia ? item.vi4_hora_asistencia : '' }}
                </span>
                <div v-if="item.vi4 == getdatosUsuario.iderp && ( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lvi4 ">
                  <v-chip small rounded color="pink" dark v-for="(leccion, i ) in item.lvi4.split(',')" :key="i" class="mt-1">
                    {{ leccion }}
                  </v-chip>
                </div>
                <div v-if="( item.viernes == 1 || item.sabado == 1 || item.domingo == 1 ) && item.lu1_bitacora ">
                  <v-chip small rounded color="lime accent-4" dark class="mt-1 black--text" @click="verBitacora('vi4', item.vi4, item.idrol_clases)">
                    Bitacora
                  </v-chip>
                </div>
		          </template>
		        </v-data-table>
      	  </v-card-text>
      	</v-card>

      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogZoom"
      max-width="400"
      v-if="codigo"
    >
      <v-card>
        <v-card-title class="text-h5">
          Codigo Clase
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="codigo.codigos"
            rows="3"
            auto-grow
            label="Agregar acceso al zoom"
            filled
            hide-details
            readonly
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="dialogZoom = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-btn
            color="orange"
            tile
            dark
            small
            @click="copiarPortapapeles( codigo.codigos )"
          >
            <v-icon small left>mdi-content-copy</v-icon>
            Copiar
          </v-btn>

          <v-btn
            color="green"
            tile
            dark
            small
            @click="abrirZoom( codigo.codigos )"
          >
            <v-icon small left>mdi-lock-open</v-icon>
            Abrir Zoom
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para agregar un maestro al grupo -->
    <v-dialog
      v-model="dialogBitacora"
      max-width="500px"
      transition="dialog-transition"
      persistent
    >
      <v-card>
        <v-card-title primary-title>
          Bitacora
        </v-card-title>
        <v-card-text>
          <div v-if="bitacora">
            <b>Fecha creación:</b> {{ bitacora.fecha }}
            <br/> 
            <b>Teacher:</b> {{ bitacora.teacher }} 
            <br/> 
            <b>Enc / Rec:</b> {{ bitacora.usuario }} 

          </div>
          <br/>

          <!-- Aquí van las preguntas de la bitacora -->
          <span v-for="(pregunta, i) in preguntas" :key="i">
            <v-icon 
              v-text="pregunta.correcto ? 'mdi-check-bold' : 'mdi-close' " 
              :color="pregunta.correcto ? 'green' : 'red' " 
              left
            ></v-icon>
            {{ pregunta.pregunta }}
            <br/>
            <v-chip v-if="pregunta.aceptado == 1" color="green" small dark>Aceptado</v-chip>
            <v-chip v-if="pregunta.aceptado == 2" color="red"   small dark>Rechazado</v-chip>
            <v-radio-group
              v-model="pregunta.aceptado"
              row
              hide-details
              small
              dense
              :readonly="pregunta.firma == 1 ? true : false "
            >
              <v-radio
                label="Acepto"
                :value="1"
              ></v-radio>
              <v-radio
                label="Rechazo"
                :value="2"
              ></v-radio>
            </v-radio-group>
            <br/>
            <v-textarea
              rows="2"
              auto-grow
              filled
              :readonly="bitacora.firma == 1 ? true : false "
              v-model="pregunta.motivo"
              v-if="pregunta.aceptado == 2"
              label="Motivo de rechazo"
            />
            <br/>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn 
            color="black"
            small 
            rounded 
            @click="dialogBitacora = false" 
            dark
            tile
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn 
            color="green"
            small 
            rounded 
            @click="save()" 
            dark
            tile
            v-if="bitacora && bitacora.firma == 0"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialogMaps
      v-if="dialogMaps.estatus"
      :dialogMaps="dialogMaps"
      :codigo="codigo"
    />

    <dialogFalta
      v-if="dialogFalta.estatus"
      :dialogFalta="dialogFalta"
      :id_teacher="id_teacher"
      :bloque="bloque"
      :grupo="grupo"
      @consultar="consultar"
    />

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import dialogMaps            from '@/components/roles/dialogMaps.vue';
  import dialogFalta           from '@/components/roles/dialogFalta.vue';


  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'
  import filtrosRoles          from '@/mixins/filtrosRoles'


  export default {

  	components:{
      Alerta,
      carga,
      dialogMaps,
      dialogFalta
    },

    mixins: [ validarErrores, funcionesExcel, filtrosRoles ],

    data: () => ({

      confirmaRol: true,
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      grupoRolesPerfecto:[],
      search:'',
      gruposRoles: [],
      ciclos:[],
      ciclo:null,

      teacher: null,
      dialogReemplazo: false,
      teachers:[],
      teacherReemplazo: null,
      bloque: '',

      // Filtros avanzados
      niveles:[],
      nivel: null,

      planteles: [],
      plantel: null,

      horarios:[],
      horario: null,

      modalidades:[],
      modalidad:null,

      teachersFiltros:[],
			teacherFilter: null,

			dialogMaestro: false,

			escuela: 1,
			opAsignacion: null,

      codigo: null,
      dialogZoom: false,
      dialogMaps : {
        estatus:false
      },

      dialogBitacora: false,
      preguntas:[],
      bitacora: null,

      pagos:[],

      totalHoras: 0,
      pagoTotal: 0,

      bloque: '',
      id_teacher: 0,
      dialogFalta: {
        estatus: false
      },
      grupo: null,

      teachersJSON:[],
      teacherJSON:{},
      teacherGruposJSON:[]
    }),

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
        }
      }
    },

    async created () {
      await this.initialize()
      await this.getTeachersActivos()
    },

    computed:{

    	...mapGetters('login', ['getdatosUsuario']),

      totalClases( ){
        let bloqueInicial = [1,2].includes( this.selectedHeaders ) ? 1 : 3
        let bloqueFinal   = [1,2].includes( this.selectedHeaders ) ? 2 : 4

        // Vamos a sacar los teachers de cada bloque
        let tlu1 = this.filterGrupos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueInicial}`] == this.getdatosUsuario.iderp })

        let tma1 = this.filterGrupos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueInicial}`] == this.getdatosUsuario.iderp })

        let tmi1 = this.filterGrupos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueInicial}`] == this.getdatosUsuario.iderp })

        let tju1 = this.filterGrupos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueInicial}`] == this.getdatosUsuario.iderp })

        let tvi1 = this.filterGrupos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueInicial}`] == this.getdatosUsuario.iderp })
        // Vamos a sacar los teachers de cada bloque
        let tlu2 = this.filterGrupos.filter( el => { return ( el.lunes || el.domingo || el.sabado ) && el[`lu${bloqueFinal}`] == this.getdatosUsuario.iderp })

        let tma2 = this.filterGrupos.filter( el => { return ( el.martes || el.domingo || el.sabado ) && el[`ma${bloqueFinal}`] == this.getdatosUsuario.iderp })

        let tmi2 = this.filterGrupos.filter( el => { return ( el.miercoles || el.domingo || el.sabado ) && el[`mi${bloqueFinal}`] == this.getdatosUsuario.iderp })

        let tju2 = this.filterGrupos.filter( el => { return ( el.jueves || el.domingo || el.sabado ) && el[`ju${bloqueFinal}`] == this.getdatosUsuario.iderp })

        let tvi2 = this.filterGrupos.filter( el => { return ( el.viernes || el.domingo || el.sabado ) && el[`vi${bloqueFinal}`] == this.getdatosUsuario.iderp })

        console.log( tlu1.length , tma1.length , tmi1.length , tju1.length , tvi1.length , tlu2.length , tma2.length , tmi2.length , tju2.length , tvi2.length )

        // Concatenamos a los maestros
        let arregloTeachers = tlu1.length + tma1.length + tmi1.length + tju1.length + tvi1.length + tlu2.length + tma2.length + tmi2.length + tju2.length + tvi2.length
 
        return arregloTeachers
      },
    },

    methods: {
      // Funcion para cargar datos iniciales
      initialize () {
      	this.cargar = true
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }


          // this.ciclos = this.ciclos.filter( el => el.ciclo.match('05') || el.ciclo.match('07') || el.ciclo.match('06') )
      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTeachersActivos( ){
      	this.cargar = true
      	this.teachers = []
        return this.$http.get('academico.teachers.activos').then(response=>{
          this.teachers = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      consultar () {
        this.consultar2()
      	if( !this.ciclo ){
      		return this.validarErrorDirecto('Selecciona un ciclo por favor')
      	}
      	this.gruposRoles = []
      	this.cargar      = true
        this.$http.post('rol.clases', this.ciclo ).then(response=>{
          this.pagos       = response.data.teachers

          this.gruposRoles = response.data.respuesta.filter( el => { return el.liberar == 1 && ( el.lu1 == this.getdatosUsuario.iderp || el.ma1 == this.getdatosUsuario.iderp || el.mi1 == this.getdatosUsuario.iderp || el.ju1 == this.getdatosUsuario.iderp || el.vi1 == this.getdatosUsuario.iderp || el.lu2 == this.getdatosUsuario.iderp || el.ma2 == this.getdatosUsuario.iderp || el.mi2 == this.getdatosUsuario.iderp || el.ju2 == this.getdatosUsuario.iderp || el.vi2 == this.getdatosUsuario.iderp || el.lu3 == this.getdatosUsuario.iderp || el.ma3 == this.getdatosUsuario.iderp || el.mi3 == this.getdatosUsuario.iderp || el.ju3 == this.getdatosUsuario.iderp || el.vi3 == this.getdatosUsuario.iderp || el.lu4 == this.getdatosUsuario.iderp || el.ma4 == this.getdatosUsuario.iderp || el.mi4 == this.getdatosUsuario.iderp || el.ju4 == this.getdatosUsuario.iderp || el.vi4 == this.getdatosUsuario.iderp) })

          // de esos grupos, hay que ver si hay algun grupo que no esté confirmado

          for( const i in  this.gruposRoles ){

            if( [0,2].includes( this.gruposRoles[i]['lu1_confirmacion'] ) && this.gruposRoles[i]['lu1'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ma1_confirmacion'] ) && this.gruposRoles[i]['ma1'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['mi1_confirmacion'] ) && this.gruposRoles[i]['mi1'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ju1_confirmacion'] ) && this.gruposRoles[i]['ju1'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['vi1_confirmacion'] ) && this.gruposRoles[i]['vi1'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            // *******************

            if( [0,2].includes( this.gruposRoles[i]['lu2_confirmacion'] ) && this.gruposRoles[i]['lu2'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ma2_confirmacion'] ) && this.gruposRoles[i]['ma2'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['mi2_confirmacion'] ) && this.gruposRoles[i]['mi2'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ju2_confirmacion'] ) && this.gruposRoles[i]['ju2'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['vi2_confirmacion'] ) && this.gruposRoles[i]['vi2'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            // *****************
            if( [0,2].includes( this.gruposRoles[i]['lu3_confirmacion'] ) && this.gruposRoles[i]['lu3'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ma3_confirmacion'] ) && this.gruposRoles[i]['ma3'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['mi3_confirmacion'] ) && this.gruposRoles[i]['mi3'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ju3_confirmacion'] ) && this.gruposRoles[i]['ju3'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['vi3_confirmacion'] ) && this.gruposRoles[i]['vi3'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            // **************
            if( [0,2].includes( this.gruposRoles[i]['lu4_confirmacion'] ) && this.gruposRoles[i]['lu4'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ma4_confirmacion'] ) && this.gruposRoles[i]['ma4'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['mi4_confirmacion'] ) && this.gruposRoles[i]['mi4'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['ju4_confirmacion'] ) && this.gruposRoles[i]['ju4'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}

            if( [0,2].includes( this.gruposRoles[i]['vi4_confirmacion'] ) && this.gruposRoles[i]['vi4'] == this.getdatosUsuario.iderp ){ this.confirmaRol = true; return  }else{ this.confirmaRol = false}
            
          }



          //******************************************************************************************
          //******************************************************************************************

          let id_teacher = this.getdatosUsuario.iderp

          let bloqueInicial = [1,2].includes( this.selectedHeaders ) ? 1 : 3
          let bloqueFinal   = [1,2].includes( this.selectedHeaders ) ? 2 : 4

          // Sacamos las clases que le pertenecen al teacher 
          const clasesTeacher = this.filterGrupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueInicial}`] == id_teacher) || ['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueFinal}`] == id_teacher)) && ( !el.domingo && !el.sabado && el.horas_dia != 1.5 ));

          // SACAR LAS CLASES DOMINICALES
          const clasesTeacherDomingo = this.filterGrupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.domingo );

          // SACAR LAS CLASES SABATINO
          const clasesTeacherSabado = this.filterGrupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.sabado );

          // CLASES DE 1.5 HORAS
          const clasesLMV = this.filterGrupos.filter(el => (['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueInicial}`] == id_teacher ) || ['lu', 'ma', 'mi', 'ju', 'vi'].some(dia => el[`${dia}${bloqueFinal}`] == id_teacher ) ) && el.horas_dia == 1.5 );

          // Lo separamos por escuela
          const clasesFast = clasesTeacher.filter( el => { return el.id_unidad_negocio == 2})
          const clasesInbi = clasesTeacher.filter( el => { return el.id_unidad_negocio == 1})

          // CLASES FAST DOMINGO
          const clasesFastDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 2})
          const clasesInbiDomingo = clasesTeacherDomingo.filter( el => { return el.id_unidad_negocio == 1})

          // CLASES FAST SABADO
          const clasesFastSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 2})
          const clasesInbiSabado = clasesTeacherSabado.filter( el => { return el.id_unidad_negocio == 1})

          // CLASES LMV
          const clasesFastLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 2})
          const clasesInbiLMV = clasesLMV.filter( el => { return el.id_unidad_negocio == 1})

          // CONTADOR ENTRE SEMANA
          let contadorBloquesFast = 0
          for( const i in clasesFast ){

            // ENTRE SEMANA
            contadorBloquesFast += clasesFast[i][`lu${bloqueInicial}`] == id_teacher && clasesFast[i].lunes     ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`ma${bloqueInicial}`] == id_teacher && clasesFast[i].martes    ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`mi${bloqueInicial}`] == id_teacher && clasesFast[i].miercoles ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`ju${bloqueInicial}`] == id_teacher && clasesFast[i].jueves    ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`vi${bloqueInicial}`] == id_teacher && clasesFast[i].viernes   ? clasesFast[i].horas_dia : 0
            // ENTRE SEMANA
            contadorBloquesFast += clasesFast[i][`lu${bloqueFinal}`] == id_teacher && clasesFast[i].lunes     ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`ma${bloqueFinal}`] == id_teacher && clasesFast[i].martes    ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`mi${bloqueFinal}`] == id_teacher && clasesFast[i].miercoles ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`ju${bloqueFinal}`] == id_teacher && clasesFast[i].jueves    ? clasesFast[i].horas_dia : 0
            contadorBloquesFast += clasesFast[i][`vi${bloqueFinal}`] == id_teacher && clasesFast[i].viernes   ? clasesFast[i].horas_dia : 0

          }

          // CONTADOR ENTRE SEMANA
          let contadorBloquesFastDomingo = 0
          for( const i in clasesFastDomingo ){

            // DOMINICALES
            contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

            contadorBloquesFastDomingo += clasesFastDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesFastDomingo += clasesFastDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

          }


          // CONTADOR ENTRE SEMANA
          let contadorBloquesFastSabado = 0
          for( const i in clasesFastSabado ){

            // DOMINICALES
            contadorBloquesFastSabado += clasesFastSabado[i][`lu${bloqueInicial}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`ma${bloqueInicial}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`mi${bloqueInicial}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`ju${bloqueInicial}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`vi${bloqueInicial}`] == id_teacher ? 1 : 0

            contadorBloquesFastSabado += clasesFastSabado[i][`lu${bloqueFinal}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`ma${bloqueFinal}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`mi${bloqueFinal}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`ju${bloqueFinal}`] == id_teacher ? 1 : 0
            contadorBloquesFastSabado += clasesFastSabado[i][`vi${bloqueFinal}`] == id_teacher ? 1 : 0
            
          }


          // CONTADOR ENTRE SEMANA
          let contadorBloquesFastLMV = 0
          for( const i in clasesFastLMV ){

            // ENTRE SEMANA
            contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

            contadorBloquesFastLMV += clasesFastLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesFastLMV[i].lunes     ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesFastLMV[i].martes    ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].miercoles ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesFastLMV[i].jueves    ? clasesFastLMV[i].horas_dia : 0
            contadorBloquesFastLMV += clasesFastLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesFastLMV[i].viernes   ? clasesFastLMV[i].horas_dia : 0

          }

          // INBIIIIIIIIIII CLASES ENTRE SEMANA
          // CONTADOR ENTRE SEMANA
          let contadorBloquesINBI = 0
          for( const i in clasesInbi ){

            // ENTRE SEMANA BOQUE 1 o 3
            contadorBloquesINBI += clasesInbi[i][`lu${bloqueInicial}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`ma${bloqueInicial}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`mi${bloqueInicial}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`ju${bloqueInicial}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`vi${bloqueInicial}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0
            // ENTRE SEMANA BOQUE 2 o 4
            contadorBloquesINBI += clasesInbi[i][`lu${bloqueFinal}`] == id_teacher && clasesInbi[i].lunes     ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`ma${bloqueFinal}`] == id_teacher && clasesInbi[i].martes    ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`mi${bloqueFinal}`] == id_teacher && clasesInbi[i].miercoles ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`ju${bloqueFinal}`] == id_teacher && clasesInbi[i].jueves    ? clasesInbi[i].horas_dia : 0
            contadorBloquesINBI += clasesInbi[i][`vi${bloqueFinal}`] == id_teacher && clasesInbi[i].viernes   ? clasesInbi[i].horas_dia : 0

          }

          // CONTADOR ENTRE SEMANA
          let contadorBloquesInbiDomingo = 0
          for( const i in clasesInbiDomingo ){

            // DOMINICALES
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
            contadorBloquesInbiDomingo += clasesInbiDomingo[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0

          }

          // CONTADOR ENTRE SEMANA
          let contadorBloquesInbiSabado = 0
          for( const i in clasesInbiSabado ){

            const { grupo } = clasesInbiSabado[i]

            if( grupo.match('TEENS') ){



              if( clasesInbiSabado[i][`lu${bloqueInicial}`] == id_teacher ){

                contadorBloquesInbiSabado += 3
              }

              if( clasesInbiSabado[i][`lu${bloqueFinal}`] == id_teacher ){

                contadorBloquesInbiSabado += 3
              }

            }else{
              // DOMINICALES
              contadorBloquesInbiSabado += clasesInbiSabado[i][`lu${bloqueInicial}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`ma${bloqueInicial}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`mi${bloqueInicial}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`ju${bloqueInicial}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`vi${bloqueInicial}`] == id_teacher  ? 1 : 0

              contadorBloquesInbiSabado += clasesInbiSabado[i][`lu${bloqueFinal}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`ma${bloqueFinal}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`mi${bloqueFinal}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`ju${bloqueFinal}`] == id_teacher  ? 1 : 0
              contadorBloquesInbiSabado += clasesInbiSabado[i][`vi${bloqueFinal}`] == id_teacher  ? 1 : 0
            }


          }

          // CONTADOR ENTRE SEMANA
          let contadorBloquesInbiLMV = 0
          for( const i in clasesInbiLMV ){

            // ENTRE SEMANA
            contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueInicial}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

            contadorBloquesInbiLMV += clasesInbiLMV[i][`lu${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].lunes     ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`ma${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].martes    ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`mi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].miercoles ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`ju${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].jueves    ? clasesInbiLMV[i].horas_dia : 0
            contadorBloquesInbiLMV += clasesInbiLMV[i][`vi${bloqueFinal}`] == id_teacher && clasesInbiLMV[i].viernes   ? clasesInbiLMV[i].horas_dia : 0

          }

          const existeCategoria = this.pagos.find( el => el.id_usuario  == id_teacher )

          let hora_1 = existeCategoria ? existeCategoria.hora_1 : 0
          let hora_2 = existeCategoria ? existeCategoria.hora_2 : 0
          let hora_3 = existeCategoria ? existeCategoria.hora_3 : 0

          // contadorBloquesFastSabado = 0
          // contadorBloquesInbiSabado = 0

          // // AGREGAREMOS LOS PAGOS
          // arregloSinDuplicados[i]['$_HORA_L_V']  = hora_1
          // arregloSinDuplicados[i]['$_HORA_SAB']  = hora_2
          // arregloSinDuplicados[i]['$_HORA_DOM']  = hora_3

          // /***********************************************************************/
          // arregloSinDuplicados[i]['CLASES_ENTRE_SEMANA_FAST']  = clasesFast.length
          // arregloSinDuplicados[i]['HORAS_ENTRE_SEMANA_FAST']   = contadorBloquesFast

          // arregloSinDuplicados[i]['CLASES_DOMINGO_FAST']       = clasesFastDomingo.length
          // arregloSinDuplicados[i]['HORAS_DOMINGO_FAST']        = contadorBloquesFastDomingo

          // // arregloSinDuplicados[i]['CLASES_SABADO_FAST']        = clasesFastSabado.length
          // // arregloSinDuplicados[i]['HORAS_SABADO_FAST']         = contadorBloquesFastSabado

          // arregloSinDuplicados[i]['CLASES_LMV_FAST']           = clasesFastLMV.length
          // arregloSinDuplicados[i]['HORAS_LMV_FAST']            = contadorBloquesFastLMV

          // arregloSinDuplicados[i]['CLASES_TOTALTES_FAST']      = clasesFast.length + clasesFastDomingo.length + clasesFastLMV.length + clasesFastSabado.length
          // arregloSinDuplicados[i]['HORAS_TOTALES_FAST']        = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesFastSabado

          // // COSTOS HORAS
          // arregloSinDuplicados[i]['PAGO_ENTRE_SEMANA_FAST']   = contadorBloquesFast * hora_1
          // arregloSinDuplicados[i]['PAGO_DOMINGO_FAST']        = contadorBloquesFastDomingo * hora_3
          // // arregloSinDuplicados[i]['PAGO_SABADO_FAST']         = contadorBloquesFastSabado * hora_2
          // arregloSinDuplicados[i]['PAGO_LMV_FAST']            = contadorBloquesFastLMV * hora_1
          // arregloSinDuplicados[i]['PAGO_TOTAL_FAST']          = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 )
          // // arregloSinDuplicados[i]['PAGO_TOTAL_FAST']          = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastSabado * hora_2 ) + ( contadorBloquesFastLMV * hora_3 )

          // /************************************************************************/

          // arregloSinDuplicados[i]['CLASES_ENTRE_SEMANA_INBI']  = clasesInbi.length
          // arregloSinDuplicados[i]['HORAS_ENTRE_SEMANA_INBI']   = contadorBloquesINBI

          // arregloSinDuplicados[i]['CLASES_DOMINGO_INBI']       = clasesInbiDomingo.length
          // arregloSinDuplicados[i]['HORAS_DOMINGO_INBI']        = contadorBloquesInbiDomingo

          // // arregloSinDuplicados[i]['CLASES_SABADO_INBI']        = clasesInbiSabado.length
          // // arregloSinDuplicados[i]['HORAS_SABADO_INBI']         = contadorBloquesInbiSabado

          // arregloSinDuplicados[i]['CLASES_LMV_INBI']           = clasesInbiLMV.length
          // arregloSinDuplicados[i]['HORAS_LMV_INBI']            = contadorBloquesInbiLMV

          // arregloSinDuplicados[i]['CLASES_TOTALTES_INBI']      = clasesFast.length + clasesFastDomingo.length + clasesFastLMV.length + clasesFastSabado.length
          // arregloSinDuplicados[i]['HORAS_TOTALES_INBI']        = contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV + contadorBloquesInbiSabado

          // arregloSinDuplicados[i]['PAGO_ENTRE_SEMANA_INBI']   = contadorBloquesINBI * hora_1
          // arregloSinDuplicados[i]['PAGO_DOMINGO_INBI']        = contadorBloquesInbiDomingo * hora_3
          // // arregloSinDuplicados[i]['PAGO_SABADO_INBI']         = contadorBloquesInbiSabado * hora_2
          // arregloSinDuplicados[i]['PAGO_LMV_INBI']            = contadorBloquesInbiLMV * hora_1
          // arregloSinDuplicados[i]['PAGO_TOTAL_INBI']          = ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 )
          // // arregloSinDuplicados[i]['PAGO_TOTAL_INBI']          = ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_3 ) + ( contadorBloquesInbiSabado * hora_2 )

          // /************************************************************************/

          // arregloSinDuplicados[i]['CLASES_TOTALTES']           = clasesFast.length + clasesFastDomingo.length + clasesFastLMV.length + clasesInbi.length + clasesInbiDomingo.length + clasesInbiLMV.length + clasesFastSabado.length + clasesFastSabado.length
          // arregloSinDuplicados[i]['HORAS_TOTALES']             = contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV + contadorBloquesFastSabado + contadorBloquesInbiSabado

          // arregloSinDuplicados[i]['PAGO_ENTRE_SEMANA']         = ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesFast * hora_1 )
          // arregloSinDuplicados[i]['PAGO_DOMINGO']              = ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesFastDomingo * hora_3 )
          // // arregloSinDuplicados[i]['PAGO_SABADO']               = ( contadorBloquesInbiSabado * hora_2 ) + ( contadorBloquesFastSabado * hora_2 )
          // arregloSinDuplicados[i]['PAGO_LMV']                  = ( contadorBloquesInbiLMV * hora_1 ) + ( contadorBloquesFastLMV * hora_1 )
          // // arregloSinDuplicados[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastSabado * hora_2 ) + ( contadorBloquesFastLMV * hora_3 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_3 ) + ( contadorBloquesInbiSabado * hora_2 )
          // arregloSinDuplicados[i]['PAGO_TOTAL']                = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 )

          this.totalHoras =  contadorBloquesFast + contadorBloquesFastDomingo + contadorBloquesFastLMV + contadorBloquesFastSabado + contadorBloquesINBI + contadorBloquesInbiDomingo + contadorBloquesInbiLMV + contadorBloquesInbiSabado

          this.pagoTotal  = ( contadorBloquesFast * hora_1 ) + ( contadorBloquesFastDomingo * hora_3 ) + ( contadorBloquesFastLMV * hora_1 ) + ( contadorBloquesFastSabado * hora_2 ) + ( contadorBloquesINBI * hora_1 ) + ( contadorBloquesInbiDomingo * hora_3 ) + ( contadorBloquesInbiLMV * hora_1 ) + ( contadorBloquesInbiSabado * hora_2 )

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      /* CONSULTAR NUEVA FUNCION */
      consultar2(){
        console.log(this.ciclo)
        if(this.ciclo){
          this.$http.get('rol.consultarJSON/'+ this.ciclo.ciclo ).then(response=>{
            this.teachersJSON = response.data.paquetes
            this.teacherJSON = this.teachersJSON.find(el=>el.idTeacherAsignado == this.getdatosUsuario.iderp && el.lock && response.data.liberado)
            this.teacherGruposJSON = this.teacherJSON ? this.teacherJSON.grupos : []
            
            this.teacherGruposJSON.sort((a,b)=>{
              if(!a.sabado.activo && !a.domingo.activo && (b.sabado.activo || b.domingo.activo)){
                return -1; // Coloca 'a' antes de 'b' en el array
              }
              if(!a.sabado.activo && !a.domingo.activo && !b.sabado.activo && !b.domingo.activo){
                if(a.nombre_grupo.toLowerCase().includes('primera parte')){
                  return -1; 
                }
              }
              if(a.sabado.activo && b.domingo.activo){
                return -1;
              }
            })

            this.teacherGruposJSON.forEach(grupo=>{
              grupo.nombre_grupo = grupo.nombre_grupo.toLowerCase().includes('primera parte') ? grupo.nombre_grupo.replace(/Primera parte/g, '') : grupo.nombre_grupo.replace(/Segunda parte/g, '');
              grupo.nombre_grupo = grupo.nombre_grupo.replace(/\(([^)]+)\)/g, '$1');
            })
          }).finally( () => { })
        }
      },

      aceptarRol(estatus){
        if(this.ciclo){
          this.cargar = true;
          let payload = {
              aceptarRol: {
                idTeacherReal : this.getdatosUsuario.iderp,
                aceptadoXTeacher : estatus,
              },
              id_ciclo:this.ciclo
            };
          this.$http.post('rol.aceptarTeacherRolJSON', payload).then(response=>{
            if (this.teacherJSON) {
              this.teacherJSON.aceptadoXTeacher = estatus;
              this.teachersJSON.forEach(teacher => {
                if (teacher.idTeacher == this.teacherJSON.idTeacher) {
                  teacher.aceptadoXTeacher = estatus;
                }
              });
            }
            this.validarSuccess( 'Se actualizo el estatus del rol' )
          }).catch( error =>{
              this.validarError( error.response.data.message )
          }).finally( () => { 
              this.cargar = false
          })
        }
      },

      formatMoney(value) {
        // Formateamos el número como moneda en dólares (USD)
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      },

      confirmar(){
        this.cargar = true
        const payload = {
          id_teacher: this.getdatosUsuario.iderp,
          grupos: this.gruposRoles
        }
        return this.$http.post('rol.clases.confirmar', payload ).then(response=>{
          console.log( response.data )
          this.teachers = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      rechazar(){
        this.cargar = true
        const payload = {
          id_teacher: this.getdatosUsuario.iderp,
          grupos: this.gruposRoles
        }
        return this.$http.post('rol.clases.rechazar', payload ).then(response=>{
          console.log( response.data )
          this.teachers = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      exportar( ){
      	this.exportExcel( this.filterGrupos  , 'ROL_CLASES')
      },

      copiarPortapapeles ( item ) {

        // Expresión regular para encontrar enlaces en el texto
        var expresionRegular = /https?:\/\/[^\s]+/g;

        // Usar el método match() para obtener todos los enlaces en un arreglo
        var enlaces = item.match(expresionRegular);

        navigator.clipboard.writeText( enlaces[0] ).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      },

      abrirZoom( item ){

        // Expresión regular para encontrar enlaces en el texto
        var expresionRegular = /https?:\/\/[^\s]+/g;

        // Usar el método match() para obtener todos los enlaces en un arreglo
        var enlaces = item.match(expresionRegular)[0];

        window.open(enlaces, "_blank");

      },

      verBitacora( bloque, id_teacher, idrol_clases ){

        this.dialogBitacora = false 

        const payload = {
          bloque, 
          id_teacher, 
          idrol_clases
        }

        this.cargar      = true
      
        this.$http.post(`bitacora.get`, payload).then(response=>{
          this.preguntas = response.data.preguntas
          this.bitacora  = response.data.bitacora
          this.dialogBitacora = true 
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      save( ){

        if( this.preguntas.find( el => el.aceptado == 0 )){
          return this.validarErrorDirecto('Favor de aceptar o rechazar todos los puntos')
        }

        const payload = {
          ...this.bitacora,
          preguntas: this.preguntas
        }

        console.log( payload )

        this.cargar      = true
      
        this.$http.put(`bitacora.update/` + this.bitacora.idrol_clases , payload).then(response=>{
          this.verBitacora( this.bitacora.bloque, this.bitacora.id_teacher, this.bitacora.idrol_clases )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      }

    },
  }

</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }


</style>