import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import XLSX from 'xlsx'

export default {
	data: () => ({
    headersBloque1: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 1'      , value: 'lu1_n'       },
      { text: 'Martes 1'     , value: 'ma1_n'       },
      { text: 'Miércoles 1'  , value: 'mi1_n'       },
      { text: 'Jueves 1'     , value: 'ju1_n'       },
      { text: 'Viernes 1'    , value: 'vi1_n'       },
    ],

    headersBloque2: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 2'      , value: 'lu2_n'       },
      { text: 'Martes 2'     , value: 'ma2_n'       },
      { text: 'Miércoles 2'  , value: 'mi2_n'       },
      { text: 'Jueves 2'     , value: 'ju2_n'       },
      { text: 'Viernes 2'    , value: 'vi2_n'       },
    ],

    headersBloque3: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 3'      , value: 'lu3_n'       },
      { text: 'Martes 3'     , value: 'ma3_n'       },
      { text: 'Miércoles 3'  , value: 'mi3_n'       },
      { text: 'Jueves 3'     , value: 'ju3_n'       },
      { text: 'Viernes 3'    , value: 'vi3_n'       },
    ],

    headersBloque4: [
      { text: 'Grupo'        , value: 'grupo'       },
      { text: 'Salón'        , value: 'salon'       },
      { text: 'Inicio'       , value: 'hora_inicio' },
      { text: 'Final'        , value: 'hora_fin'    },
      { text: '% asist.'     , value: 'porcentaje_asistencia' },
      { text: 'Alumnos'      , value: 'cant_alumnos'},
      { text: 'Nivel'        , value: 'id_nivel'    },
      { text: 'Sucursal'     , value: 'plantel'     },
      { text: 'Modalidad'    , value: 'modalidad'   },
      { text: 'Lunes 4'      , value: 'lu4_n'       },
      { text: 'Martes 4'     , value: 'ma4_n'       },
      { text: 'Miércoles 4'  , value: 'mi4_n'       },
      { text: 'Jueves 4'     , value: 'ju4_n'       },
      { text: 'Viernes 4'    , value: 'vi4_n'       },
    ],

    headers:[],
    selectedHeaders: 1,

    checkEliminados: false,
    checkOptimizados: 3,
    curso: null,
  }),

  created(){
  	this.headers = this.headersBloque1
  },

  watch: {
  	selectedHeaders( val ){
  		switch( val ){
  			case 1: 
  				this.headers = this.headersBloque1
  			break;

  			case 2: 
  				this.headers = this.headersBloque2
  			break;

  			case 3: 
  				this.headers = this.headersBloque3
  			break;

  			case 4: 
  				this.headers = this.headersBloque4
  			break;
  		}
  	}
  },

  computed:{
  	filterGrupos(){

  		let grupos = []

  		grupos = this.gruposRoles.filter( el => { return el.deleted == 0 })
      
  		const diasSemana = ['lu', 'ma', 'mi', 'ju', 'vi'];
      const bloquesTiempo = [1, 2, 3, 4];

      grupos = grupos.filter(el => {
        for (const dia of diasSemana) {
          for (const bloque of bloquesTiempo) {
            if (el[`${dia}${bloque}`] === this.getdatosUsuario.iderp) {
              return true; // Si encuentra una coincidencia, incluye el grupo en el resultado
            }
          }
        }
        return false; // Si no hay coincidencias en ningún día ni bloque, excluye el grupo
      });

  		return grupos
  	},

    gruposCompletos( ){
      return this.filterGrupos.filter( el => { return el.lu1_n && el.ju1_n  })
    },

    gruposFaltantes( ){
      return this.filterGrupos.filter( el => { return !el.lu1_n || !el.ju1_n  })
    },

    horasSemana( ){
      console.log( this.filterGrupos[0] )
      
      return this.filterGrupos.map(item => item.horas_ciclo ).reduce((prev, curr) => prev + curr, 0)
    },

    horasCatorcena( ){
      return this.filterGrupos.map(item => item.horas_catorcena ).reduce((prev, curr) => prev + curr, 0)
    },

    horasCiclo( ){
      return this.filterGrupos.map(item => item.horas_semana ).reduce((prev, curr) => prev + curr, 0)
    }

  },

	methods: {

    exportExcel(dataExport, name){
      let data = XLSX.utils.json_to_sheet(dataExport)
      const workbook = XLSX.utils.book_new()
      const filename = name
      XLSX.utils.book_append_sheet(workbook, data, filename)

      var wbout = XLSX.write(workbook, {
        bookType: 'csv',
        bookSST: false,
        type: 'binary'
      });

      const file = new File([new Blob([this.s2ab(wbout)])], filename + '.csv')
      
      let formData = new FormData();
      // //se crea el objeto y se le agrega como un apendice el archivo 
      formData.append('file',file);
      // /*getDatosRiesgo the form data*/

      this.$http.post('pdfs',formData).then(response=> {
        window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.csv'
      }).catch(error=> {
        console.log(error);
      });
    },

    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

  }
}